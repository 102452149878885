<template>
  <section>
    <v-app-bar
        id="app-bar"
        fixed
        app
        color="white"
        flat
        style="left: 0;"
        height="90"
        elevation="3"
    >

      <router-link :to="{name: 'home'}" class="ml-5 mt-1">
        <img src="/assets/img/logo.svg" :width="130"/>
      </router-link>

      <v-spacer/>

      <v-btn
          elevation="5"
          class="ml-2 btn-support hidden-sm-and-down"
          min-width="0"
          text
          @click="redirectToShop()"
      >
        <span>Geras24 Shop</span>
      </v-btn>

      <v-btn
          v-if="!$store.getters.isLoggedIn"
          elevation="5"
          class="ml-2 btn-blue hidden-xs-only"
          min-width="0"
          text
          @click="$emit('open-register-modal')"
      >
        <span>{{ $t('create_account_for_free') }}</span>
      </v-btn>

      <v-btn
          v-if="!$store.getters.isLoggedIn"
          elevation="5"
          class="ml-2 btn-blue"
          min-width="0"
          text
          @click="$emit('open-login-modal')"
      >
        <v-icon>mdi-account-outline</v-icon>
      </v-btn>
      <v-btn
          v-else
          class="ml-2 btn-trans hidden-xs-only"
          min-width="0"
          text
          @click="logout()"
          :loading="logouting"
          :disabled="logouting"
      >
        <span>{{ $t('logout') }}</span>
        <v-icon small class="ml-2">mdi-logout</v-icon>
      </v-btn>
      <v-btn
          v-if="$store.getters.isLoggedIn && $store.getters.user.user ? $store.getters.user.user.role == 'Owner' : false"
          class="ml-2"
          min-width="0"
          text
          @click="$router.push({name: 'profile'})"
      >
        <v-avatar size="48" class="elevation-5">
          <img
              :src="$store.getters.user.user.avatar || '/assets/img/avatar.png'"
          >
        </v-avatar>
        <div v-if="$vuetify.breakpoint.mdAndUp" class="ml-5 text-body-1 main-title-color">{{ $store.getters.user.user.firstname }} {{ $store.getters.user.user.name }}</div>
      </v-btn>

      <a v-else-if="$store.getters.isLoggedIn" href="/admin/dashboard">
        <v-btn
            class="ml-2"
            min-width="0"
            text
        >
          <v-avatar size="48" class="elevation-5">
            <img
                :src="$store.getters.user.user.avatar || '/assets/img/avatar.png'"
            >
          </v-avatar>
          <div v-if="$vuetify.breakpoint.mdAndUp" class="ml-5 text-body-1 main-title-color">{{ $store.getters.user.user.firstname }} {{ $store.getters.user.user.name }}</div>
        </v-btn>
      </a>

      <v-app-bar-nav-icon
          class="header mx-2"
          @click="sideDrawer = true"
      ></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer
        v-model="sideDrawer"
        temporary
        right
        app
        style="top: 90px"
    >

      <div class="pl-6 pt-8"><img src="/assets/img/logo.svg" :width="$helpers.isMobile() ? '100px' : '130px'"/></div>

      <v-list
          class="sidebar-links"
          nav
          flat
          dense
      >
        <v-list-item-group>
          <div v-if="!$store.getters.isLoggedIn">
            <v-list-item @click="$emit('open-login-modal')">
              <v-list-item-title>{{ $t('login') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="$emit('open-register-modal')">
              <v-list-item-title>Kostenlos registrieren</v-list-item-title>
            </v-list-item>
          </div>
          <div v-else>
            <v-list-item @click="logout()">
              <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
            </v-list-item>
          </div>

          <v-list-item class="spacer"></v-list-item>

          <v-list-item to="/ueber-geras24">
            <v-list-item-title>Über Geras24</v-list-item-title>
          </v-list-item>
          <v-list-item href="https://shop.geras24.de" target="_blank">
            <v-list-item-title>Geras24 Shop</v-list-item-title>
          </v-list-item>
          <v-list-item class="spacer"></v-list-item>

          <v-list-item to="/produkte/persoenliche-botschaft">
            <v-list-item-title>Persönliche Botschaft</v-list-item-title>
          </v-list-item>
          <v-list-item to="/produkte/checkliste">
            <v-list-item-title>Checkliste</v-list-item-title>
          </v-list-item>
          <v-list-item to="/produkte/tresor">
            <v-list-item-title>Tresor</v-list-item-title>
          </v-list-item>
          <v-list-item to="/produkte/der-letzte-wunsch">
            <v-list-item-title>Der letzte Wunsch</v-list-item-title>
          </v-list-item>
          <v-list-item href="https://www.up-geras24.de/" target="_blank">
            <v-list-item-title>Up!</v-list-item-title>
          </v-list-item>

          <v-list-item class="spacer"></v-list-item>

          <v-list-item to="/hilfe">
            <v-list-item-title>Hilfe</v-list-item-title>
          </v-list-item>
          <v-list-item to="/notfallbox">
            <v-list-item-title>Notfallbox</v-list-item-title>
          </v-list-item>

          <v-list-item class="spacer"></v-list-item>

          <v-list-item to="/impressum">
            <v-list-item-title>Impressum</v-list-item-title>
          </v-list-item>
          <v-list-item to="/allgemeine-verkaufsbedingungen">
            <v-list-item-title>Verkaufsbedingungen</v-list-item-title>
          </v-list-item>
          <v-list-item to="/datenschutz">
            <v-list-item-title>Datenschutz</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

  </section>
</template>

<script>
// Components
import {VHover, VListItem} from "vuetify/lib";

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({hover}) => {
              return h(
                  VListItem,
                  {
                    attrs: this.$attrs,
                    class: {
                      "black--text": !hover,
                      "white--text secondary elevation-12": hover
                    },
                    props: {
                      activeClass: "",
                      dark: hover,
                      link: true,
                      ...this.$attrs
                    }
                  },
                  this.$slots.default
              );
            }
          }
        });
      }
    }
  },

  props: ['payload', 'showLogin'],

  data() {
    return {
      notifications: [
        "Mike John Responded to your email",
        "You have 5 new tasks",
        "You're now friends with Andrew",
        "Another Notification",
        "Another one"
      ],
      logouting: false,
      sideDrawer: false
    }
  },
  methods: {
    logout() {
      this.logouting = true
      this.$store.dispatch('logout')
          .then(() => {
            this.$router.push({name: 'home'})
          })
          .catch(err => {
            this.logouting = false
            this.$helpers.showError(err)
          })
    },
    redirectToShop() {
      window.open('https://shop.geras24.de', '_blank');
    }
  }
};
</script>
