export default {
    login: "Login",
    username_or_email: "Email-Adresse",
    password: "Passwort",
    safety: "Sicherheit",
    google: "Google",
    facebook: "Facebook",
    create_account: "Registrieren",
    no_account: "Noch kein Account? ",
    create_account_for_free: "Kostenlos registrieren",
    for_personal_or_business: "für privat oder geschäftlich",
    forget_password: "Passwort vergessen?",
    success_verify: "Ihr Account wurde erfolgreich verifiziert! Sie können sich jetzt einloggen!",
    success_login: "Willkommen bei Geras24!",
    enter_username: "Bitte geben Sie den Benutzernamen oder Ihre Email ein!",
    enter_password: "Bitte geben Sie Ihr Passwort ein!",
    redirect_to_provider: "Weiterleitung zur Anbieterseite ...",
    register: "Registrieren",
    name: "Name",
    first_name: "Vorname",
    last_name: "Nachname",
    username: "Benutzername",
    email: "Email",
    password_confirm: "Passwort bestätigen",
    referral_code: "Referenzcode",
    with_your_account: "mit Ihrem Konto",
    enter_firstname: "Bitte geben Sie Ihren Vornamen ein!",
    enter_name: "Bitte geben Sie Ihren Nachnamen ein!",
    enter_email: "Bitte geben Sie Ihre Email ein!",
    enter_password_confirm: "Bitte Passwort bestätigen!",
    accept_terms: "Bitte Datenschutz bestätigen!",
    password_confirm_error: "Passwort und Bestätigung stimmen nicht überein!",
    register_send_activation_email: "Bitte klicken Sie auf den Aktivierungscode den wir Ihnen per Mail gesendet haben!",
    recover_password: "Password wiederherstellen",
    new_password: "neues Passwort",
    confirm_new_password: "Bestätigung neues Passwort",
    password_hint: "Mindestens 8 Zeichen",
    recover_send_reset_email: "Bitte klicken Sie auf den Wiederherstellungslink den wir an Ihre Email gesendet haben!",
    password_changed: "Neues Passwort erfolgreich geändert!",

    checklist_title: "Ihre persönliche Checkliste",
    checklist_subtitle: "Wenn ein Familienmitglied stirbt, müssen trotz der Trauer viele bürokratische Angelegenheiten geklärt werden. In dieser emotionalen Ausnahmesituation droht die Bürokratie uns zu überfordern.",
    checklist_checkliste: "Checkliste",
    checklist_meldepflichtig: "Meldepflichtig",
    checklist_frist_nach_todesfall: "Frist nach Todesfall",
    checklist_besonderheiten: "BESONDERHEITEN",
    checklist_notizen: "Notizen",
    checklist_versicherungen: "Versicherungen",
    checklist_mitgliedschaften: "Mitgliedschaften",

    collections_botschaften_title: "Persönliche Botschaften",
    collections_botschaften_subtitle: "Hinterlassen Sie Ihre persönlichen Botschaften in Form von Videos, Bildern, Briefen, oder Sprachnachrichten.",
    collections_wunsch_title: "Der letzte Wunsch",
    collections_wunsch_subtitle: "Hinterlassen Sie Ihre persönlichen Botschaften in Form von Videos, Bildern, Briefen, oder Sprachnachrichten.",
    collections_select_panel: "Plan auswählen:",

    empty_trusters: "Sie haben keine Vertrauensperson gewählt!",
    cancel: "Abbrechen",
    save: "speichern",
    photos: "Fotos",
    documents: "Briefe",
    videos: "Videos",
    audios: "Sprachnachrichten",
    create_new_category: "Erste Kategorie erstellen",
    play: "Play",
    description: "Beschreibung",
    size: "Größe",
    date: "Datum",
    actions: "Aktionen",

    upload_new_file: "Laden Sie hier weitere Dokumente hoch",
    no_file: "Keine Datei vorhanden!",
    are_you_sure: "Sind Sie sicher?",
    delete_file_alert: "Das Löschen dieser Datei ist nicht mehr möglich. Diese Datei wird auch aus der Liste der vertrauenswürdigen Benutzerdateien entfernt",
    delete_category_alert: "Durch das Löschen der Kategorie werden alle darin enthaltenen Dateien gelöscht und können nicht mehr wiederhergestellt werden",
    trusted_dialog: "Vertrauenspersonen",
    accessed_users_to_file: "Liste Ihrer vertrauenswürdigen Benutzer, die auf diese Datei zugreifen. Sie können diese Benutzer hinzufügen oder entfernen!",
    add_new: "Neue hinzufügen",
    trusted_user: "Vertrauensperson",
    trusted_group: "Vertrauens Gruppe",
    category_name: "Kategorie Name",
    yes: "Ja",
    no: "Nein",
    later: "Später",
    buy_now: "Zu den Mitgliedschaften",
    buy: "Kaufen",
    need_payment: "Keine Mitgliedschaft vorhanden",
    need_payment_desc: "Um auf diesen Bereich zugreifen zu können, müssen Sie eine Mitgliedschaft abschließen.",

    upload_new_media_subtitle: "Dateiformate: JPG, JPEG, PNG, BMP, GIF, SVG, AVI, MP4, MKV, FLV, WEBM, MOV, WMV, MP3, WAV, M4A, OGG",
    upload_new_document_subtitle: "Dateiformate: TXT, DOC, DOCX, PDF, ZIP, XLSX, PPT, PPTX",
    uploading: "Uploading ... bitte warten",
    upload: "Upload",
    dont_close_page: "Bitte die Seite nicht schliessen",
    file: "Datei",
    new_file: "Neue Datei",
    select_file: "Bitte Dateien wählen",
    file_desc: "Datei Bezeichnung",
    media_type_alert: "Die Datei muss der Typ der entsprechenden Mediadateien sein",
    file_stored: "Neue Datei gespeichert!",

    logout: "Abmelden",
    logout_admin: "Admin verlassen",

    dashboard: "Dashboard",
    botschaften: "Persönliche Botschaften",
    checkliste: "Checkliste",
    tresor: "Tresor",
    trusted_menu: "Vertrauenspersonen",
    up: "Up",
    up_subtitle: "Dieser Bereich ist in Kürze für Sie verfügbar. Wir werden Sie über Neuigkeiten informieren.",
    support: "Support",
    support_tickets: "Support Tickets",
    profile: "Profil",
    wunsch: "Der letzte Wunsch",
    pending: 'Ausstehend',
    open: "Offen",
    closed: "Geschlossen",
    close: "Schließen",
    comments: "Bemerkungen",
    accepted: "Akzeptiert",
    rejected: "abgelehnt",
    pays: "Zahlungen",
    all: "alle",
    successful: "erfolgreich",
    amount: "Betrag",
    death_certificates: "Sterbeurkunden",

    information: "Persönliche Daten",
    personal_information: "Persönliche Informationen",
    keys: "Schlüssel",
    account: "Zahlung",
    trusters: "Vertrauter",
    truster: "Vertrauter",
    notifications: "Benachrichtigungen",
    tap_to_edit: "Klicken um zu ändern",
    status: 'Status',
    plan_subscription: 'Mitgliedschaft',
    subscription_users: 'Abonnement-Nutzer',

    age: "Alter",
    martial_status: "Familienstand",
    verheiratet: "Verheiratet",
    single: "Ledig",
    indent_nr: "Rentenversicherungsnummer",
    address: "Adresse",
    street: "Strasse",
    house_number: "Hausnummer",
    zip: "PLZ",
    city: "Stadt",
    height: "Größe",
    spouse: "Partner",
    job: "Beruf",
    birthplace: "Geburtsort",
    gender: "Geschlecht",
    children: "Kinder",
    birthdate: "Geburtstag",
    nationality: "Nationalität",
    national_code: "Ausweis-Nr.",
    phone: "Telefon",

    keys_title: "Sicherheitsschlüssel für Verschlüsselung und Entschlüsselung",
    keys_subtitle1: "Mit den folgenden Schlüsselpaaren können Sie Ihre Dateien sicher ver- und entschlüsseln",
    keys_subtitle2: "Verschlüsselte Dateien können nur mit diesen Schlüsseln wiederhergestellt werden.",
    keys_subtitle3: "Wenn die Schlüssel verloren gehen, gehen alle damit verschlüsselten Dateien verloren.",
    keys_subtitle4: "Der private Schlüssel wird nur einmal erstellt, also seien Sie sorgfältig bei der Aufbewahrung.",
    public_key: "Öffentlicher Schlüssel",
    private_key: "Privater Schlüssel",
    generate_key_pairs: "Neue Schlüsselpaare generieren",
    revoke_keys: "Schlüssel aufheben",
    download_keys: "Herunterladen",
    please_wait: "Bitte warten ...",
    choose_prv_key: "Bitte wählen Sie Ihren privaten Schlüssel (.pem file) für Entschlüsselungsprozesse.",
    prv_pem_file: "Privater Schlüssel Pem Datei",

    prv_dont_access: "Aus Datenschutz- und Sicherheitsgründen haben wir keinen Zugriff auf Ihren Schlüssel. Achten Sie daher darauf, Ihren privaten Schlüssel aufzubewahren. Wir können Ihre Daten nicht wiederherstellen, wenn Sie Ihren Schlüssel verlieren  ",
    downloading: "Downloading, bitte warten!",
    key_updated: "Schlüssel aktualisiert!",
    notifications_subtitle: "In diesem Abschnitt werden alle Ihre Nachrichten und Aktivitäten angezeigt.",
    title: "Titel",
    message: "Nachricht",
    empty_notifications: "Derzeit ist keine Nachricht für Sie vorhanden",
    account_info: "Kontoübersicht",
    current_password: "aktives Passwort",

    pay_verify: "Zahlung überprüfen",
    pay_success: "Ihre Zahlung war erfolgreich. Ihr Abonnement ist aktualisiert worden",
    pay_error: "Ihre Zahlung ist fehlgeschlagen. Sie können zum Abschnitt Abonnements zurückkehren und Ihren Vorgang wiederholen.",
    plans: "Zahlungspläne",
    plans_subtitle: "Buchen Sie hier Ihre gewünschte Mitgliedschaft",
    remaining_days: "Tage übrig",
    days: "Tage",
    storage: "Speicherplatz",
    task_limit: "Aufgaben Limit",
    trusted_limit: "Limit an Vertrauenspersonen",
    duration: "Dauer",
    price: "Preis",
    plan_empty: "Es gibt keine Plan!",
    redirect_payment_gateway: "Weiterleitung zur Zahlungs-Seite ...",
    redirect_shop: "Du wirst nun zum Shop weitergeleitet ...",
    truster_users: "Vertrauter Benutzer",
    truster_users_subtitle: "In diesem Abschnitt können Sie Ihre Vertrauenspersonen sehen und verwalten.",
    truster_users_subtitle2: "Sterbeurkunde: Mit dieser Funktion können Sie die Sterbeurkunde der gewünschten Vertrauensperson zur Überprüfung an Geras senden.",
    uploaded_certificates: "Hochgeladene Zertifikate",
    confirm_trusted: "Akzeptieren Sie die Vertrauensanfrage?",
    truster_empty: "Es gibt keine Vertrauensperson!",
    redeem_code_success: "Ihr Abo wurde erfolgreich aktiviert",
    redeem_code_error: "Ihr Abo konnte nicht aktiviert werden",

    upload_certificates_confirm_dialog: "Beim Hochladen der Sterbeurkunde wird das Konto des Benutzers geschlossen, wenn die Sterbeurkunde bestätigt wird",
    upload_certificate_dialog_title: "Sterbeurkunde hochladen",
    upload_certificates_dialog: "Bitte senden Sie die Sterbeurkunde als Datei des Benutzers zur Überprüfung",
    certificate_file: "Sterbeurkunden-Datei",
    certificate_file_desc: "Please Enter Request Description",
    enter_certificate_file: "Please Upload Certificate File",
    enter_certificate_file_desc: "Please Write Certificate File Description",

    new_ticket: "Neues Ticket",
    new_ticket_subtitle: "Bitte schreiben Sie Ihre Nachricht und senden Sie diese an die zuständige Abteilung",
    loading: "Lädt… bitte warten",
    empty_data: "Keine Support-Tickets vorhanden",
    department: "Abteilung",
    subject: "Betreff",
    last_update: "letzte Änderung",
    error: "Fehler",
    send: "senden",
    ticket: "Ticket",
    created_at: "Erstellt am",
    new_message: "Neue Nachricht",
    tresor_subtitle: "Ein sicherer Ort für Ihre Dateien, fortschrittliche Verschlüsselung, die nur Sie und Ihre Treuhänder öffnen können",

    no_keys: "Sicherheitsschlüssel nicht gefunden!",
    no_keys_subtitle: "Um auf diesen Abschnitt zuzugreifen, müssen Sie zuerst die Sicherheitsschlüssel für Ihr Konto erstellen.",
    create_now: "Jetzt erstellen!",
    trusted_users2: "Vertraute Personen",
    trusted_users2_subtitle: "Liste Ihrer vertrauenswürdigen Benutzer, die auf diese Datei zugreifen. Sie können diese Benutzer hinzufügen oder entfernen!",
    file_upload_success: "Datei erfolgreich hochgeladen!",

    trusted_title: "Vertrauenspersonen",
    trusted_subtitle: "In diesem Abschnitt können Sie nach Benutzern suchen und sie zu Ihrer Vertrauensliste hinzufügen. Sie können sie auch in Gruppen kategorisieren",
    trusted_subtitle2: "Die Benutzer können auf Dateien, die Sie freigeben zugreifen.",
    trusted_subtitle3: "Treuhänder 1. Grades: Diese Treuhänder haben mehr Zugriff als andere. Sie können beispielsweise eine Sterbeurkunde hochladen.",

    users: "Benutzer",
    groups: "Gruppen",
    wait_to_confirm: "Warten auf Bestätigung…",
    no_trusted: "Es gibt keine Vertrauensperson!",
    new_group_name: "Neuer Gruppenname",
    add: "Hinzufügen",
    delete_trusted_alert: "Vertrauensperson wirklich entfernen?",
    delete_group_alert: "Durch das Entfernen der Gruppe werden alle Benutzer in der Gruppe und alle erteilten Berechtigungen von ihnen weggenommen.",
    resend_email_alert: "Soll die E-Mail an die Vertrauensperson erneut versendet werden?",
    truster_email_resent: "E-Mail an die Vertrauensperson wurde versendet",
    search_user: "Suche Benutzer",
    enter_username_name_to_search: "Bitte geben Sie den Namen oder Benutzernamen des Benutzers ein, den Sie zu Ihrer Liste hinzufügen möchten",
    name_username: "Name oder Benutzername",
    start_type_to_search: "Starte Eingabe zum suchen",
    group_optional: "Gruppe (optional)",
    add_to_trusted: "Hinzufügen zu meinen vertrauten Personen",
    edit_trusted: "Speichern",
    without_group: "ohne Gruppe",
    trusted_added_success: "Benutzer erfolgreich zu Ihrer Liste hinzugefügt!",
    trusted_removed_success: "Benutzer erfolgreich von Ihrer Liste entfernt!",
    group_removed: "Gruppe erfolgreich entfernt!",

    token_expired: "Ihre Push-Tan ist abgelaufen. Bitte melden Sie sich erneut an!",
    organisation: "Organisation",
    start_at: "Startdatum",
    expire_at: "Enddatum",
    start_at_hint: "Ohne Eingabe unbegrenzte Zeit!",
    expire_at_hint: "Ohne Eingabe Startdatum nach dem Tod!",

    prv_pem_invalid: "َDer Schlüssel ist ungültig. Bitte eine gültige .pem-Datei hochladen.",

    widowed: 'Verwitwet',
    divorced: 'Geschieden',
    registered_civil_partnership: 'In Eingetragener Lebenspartnerschaft',

    male: 'Männlich',
    female: 'Weiblich',
    other_gender: 'Diverse',
    invite_code: 'Code für Freunde einladen',
    copy_invite_link: "Einladungslink kopieren",
    copied: 'Kopiert!',

    features: 'Merkmale',

    add_note_to_checklist: 'Notiz hinzufügen',
    note: 'Notiz',

    send_invite_email: 'Einladungs-E-Mail senden',
    invite_email_lbl: 'Einladungs-E-Mail',
    invite_email_placeholder: 'Empfänger',
    invite_email_sended: 'Einladungs-E-Mail gesendet.',
    invite_email_empty: 'Bitte geben Sie eine E-mail-Adresse ein',

    personaldocs: 'Persönliche Unterlagen',
    documenttemps: 'Dokumentvorlagen',
    documenttemps_subtitle: 'Laden Sie hier verschiedene Dokumentvorlagen herunter.<br>Bitte beachten Sie dass Sie für die ausfüllbaren Formulare den Adobe Acrobat Reader benötigen.',

    personaldocs_title: 'Persönliche Unterlagen',
    personaldocs_subtitle: 'Laden Sie hier Ihre persönlichen Unterlagen aller Art hoch.',

    advisor: 'alleato',
    advisor_title: 'alleato',
    advisor_subtitle: 'Hier finden Sie alle Unterlagen die Ihnen von alleato zur Verfügung gestellt wurden.',

    subscriptions: 'Preismodell-Abonnement',
    subscriptions_subtitle: 'Hier finden Sie alle Nutzer für Ihr gewähltes Preismodell',

    empty_duplications: 'Ohne Abonnementbenutzer',
    expired_at: 'Abgelaufen am',

    otp_title: 'Zwei-Faktor-Authentifizierung',
    otp_setup: 'Geben Sie den 6-stelligen Sicherheitscode aus Ihrer Authentifizierungs-App ein (z.B. Google Authenticator)',
    otp_code: 'Sicherheitscode',
    success_otp_enabled: 'Zweistufige Anmeldung mit pushTAN aktiviert',
    success_otp_disabled: 'Zweistufige Anmeldung mit pushTAN deaktiviert',

    init_security_keys: 'Erstkonfiguration von Verschlüsselungen',

    add_user: 'Vertrauenspersonen hinzufügen',
    edit_user: 'Vertrauensperson editieren',
    add_trusted_user_desc: 'In diesem Abschnitt können Sie Personen eintragen, denen Sie vertrauen.',

    your_pays: 'Ihre Zahlungen',
    your_pays_desc: 'Hier finden Sie den Zahlungsbericht',
    empty_your_pays: 'Sie haben derzeit keine registrierten Zahlungen',

    payment_title: 'Bestelldetails',
    payment_plan_price: 'Preis',
    payment_amount: 'Zahlungsbetrag',
    paymeny_go_bank: 'Zahlen Sie mit PayPal',
    payment_plan: 'Ausgewählter Plan',

    ref: 'Ref.',

    started: 'Warten auf Zahlung',

    send_contact_email_success: 'Vielen Dank für Deine Nachricht',

    not_activated: "Nicht aktiviert",
    activated: "Aktiv",
    your_abos: "Ihre Mitgliedschaft",
    your_abos_subtitle: "Verwalten Sie hier Ihre Mitgliedschaft.",
    your_abos_list: "Gebuchte Mitgliedschaft",
    no_abos: "Noch keine gebuchte Mitgliedschaft vorhanden. Bitte wählen Sie eine Mitgliedschaft oder geben Sie einen bereits erhalten Code ein.",
    abonnement: 'Mitgliedschaft',

    last_wish_title: 'Der letzte Wunsch',
    last_wish_desc: 'Hinterlassen Sie hier Ihren letzten Wunsch',

    meta_tags: {
        home: {
            title: 'Geras24', description: 'Geras24'
        },
        products: {
            'der-letzte-wunsch': {
                title: 'Der letzte Wunsch - Geras24', description: 'Der letzte Wunsch'
            }, 'persoenliche-botschaft': {
                title: 'Persönliche Botschaft - Geras24', description: 'Persönliche Botschaft'
            }, checkliste: {
                title: 'Checkliste - Geras24', description: 'Checkliste'
            }, tresor: {
                title: 'Tresor - Geras24', description: 'Tresor'
            }, up: {
                title: 'Up - Geras24', description: 'Up'
            },
        }, impressum: {
            title: 'Impressum - Geras24', description: 'Impressum'
        }, datenschutz: {
            title: 'Datenschutz - Geras24', description: 'Datenschutz'
        }, sellerTerms: {
            title: 'Allgemeine Verkaufsbedingungen - Geras24', description: 'Allgemeine Verkaufsbedingungen'
        }, about: {
            title: 'Über uns - Geras24', description: 'Über uns'
        }, contactUs: {
            title: 'Kontakt - Geras24', description: 'Kontakt'
        }, faq: {
            title: 'Hilfe - Geras24', description: 'Hilfe'
        }, notfallBox: {
            title: 'Die Geras24 Notfallbox - Geras24', description: 'Geras24 Notfallbox'
        },
    },

    texts: {
        home: {
            title: 'Der digitale Ort zur Absicherung deines Lebens und der Familie', registerNowForFree: 'Jetzt kostenlos registrieren', reasons: {
                reason1: 'Innovative digitale Plattform für umfassende Familienabsicherung.',
                reason2: 'Lebenslange Begleitung von der Vorsorge bis zur Nachsorge – Wir sind für dich und deine Lieben da.',
                reason3: 'Wir kümmern uns nicht nur um dich, sondern auch um deine Familie und engsten Freunde.',
            }, usagePoints: {
                title: 'Warum du Geras24 nutzen solltest', subtitle: 'Unabhängige Statistiken zeigen, warum Geras24 die beste Wahl für dich und deine Familie ist.', defaultButtonText: 'Sicherheit im ...', point1: {
                    buttonText: 'Brandfall',
                    title: 'Sicherheit im Brandfall',
                    description: 'Jedes Jahr verlieren Menschen in Deutschland und auf der ganzen Welt durch Wohnungsbrände wertvolle Erinnerungen und wichtige Unterlagen. Videos aus der Kindheit, Familienfotos oder persönliche Andenken, die oft von unschätzbarem emotionalem Wert sind, werden unwiederbringlich zerstört. Doch neben diesen emotionalen Verlusten können auch gravierende praktische Probleme entstehen, wenn wichtige Dokumente, wie notarielle Unterlagen oder Verträge, in den Flammen verloren gehen. Der Verlust solcher Dokumente könnte beispielsweise zu rechtlichen und finanziellen Schwierigkeiten führen, wenn Nachweise über Eigentum oder Vereinbarungen fehlen. Diese Beispiele verdeutlichen, wie wichtig es ist, essentielle Unterlagen sicher aufzubewahren.'
                }, point2: {
                    buttonText: 'Hochwasserfall',
                    title: 'Sicherheit im Hochwasserfall',
                    description: 'Jedes Jahr sind Menschen in Deutschland und weltweit von den verheerenden Folgen von Hochwasser betroffen. Der Klimawandel führt zu immer häufigeren und intensiveren Starkregenereignissen, wodurch das Risiko für Überschwemmungen wächst. Diese Naturkatastrophen können nicht nur Gebäude und Infrastruktur schwer beschädigen, sondern auch wertvolle persönliche Besitztümer und wichtige Dokumente gefährden. Beispielsweise können wichtige Unterlagen wie medizinische Befunde oder Immobilienpapiere durch das Wasser unleserlich oder unbrauchbar werden. Dies kann zu Schwierigkeiten bei der Wiederbeschaffung von Dokumenten oder der Klärung von Ansprüchen führen. Angesichts der zunehmenden Gefahr von Hochwasser ist es daher entscheidend, Maßnahmen zum Schutz und zur sicheren Aufbewahrung wichtiger Unterlagen zu ergreifen.'
                }, point3: {
                    buttonText: 'Notfall',
                    title: 'Sicherheit im Notfall',
                    description: 'Die meisten Unfälle passieren im eigenen Zuhause - Tendenz steigend, weshalb es entscheidend ist, dass die medizinische Vorsorge gut organisiert ist. Patientenverfügungen, medizinische Anweisungen und Versicherungsunterlagen sollten an einem sicheren Ort aufbewahrt werden. Viele Menschen denken selten an den Notfall und haben keine klaren Anweisungen hinterlegt. Dies kann für Angehörige äußerst belastend sein, wenn sie im Ernstfall nicht wissen, ob solche Dokumente vorhanden sind oder wo sie zu finden sind. Eine gute Organisation dieser wichtigen Unterlagen kann nicht nur schnelle Entscheidungen erleichtern, sondern auch dazu beitragen, die Belastung für die Familie zu minimieren.'
                }
            }, customerFeedback: {
                title: 'Was unsere Kunden über uns denken', subtitle: 'Wir sind stolz auf unsere Zahlen und die Zufriedenheit unserer Kunden.',
            }, statistics: {
                title: 'Warum du auf Geras24 vertrauen kannst',
                subtitle: 'Die Geras24-Community wächst stetig und rettet somit aktiv Leben.',
                statisticMembers: 'Aktive Mitglieder',
                statisticEmergencyFolders: 'Verwendete Notfallordner',
                statisticEmergencyCards: 'Verwendete Notfallkarten'
            }, pricing: {
                title: 'Unsere Preise',
                subtitle: 'Im Folgenden findest du unsere verschiedenen Modelle, um Mitglied zu werden. Sichere dir jetzt ein Stück Sicherheit für dein Leben.',
                registerBookNow: 'Jetzt buchen',
                mostlyChosen: 'Am meisten gewählt',
                yes: 'Ja',
                plans: {
                    familyCarefree: {
                        title: 'Family Sorglos', subtitle: 'Volle Kostenkontrolle mit maximalem Umfang.'
                    }, lifetime: {
                        title: 'Ein Leben Lang Sorglos', subtitle: 'Von Anfang bis Ende die beste Entscheidung.'
                    }
                },
                periods: {
                    monthly: 'monatlich', yearly: 'jährlich', lifetime: 'einmalig'
                },
                features: {
                    sevenDaysTrial: '7 Tage kostenlose Testphase',
                    storage: 'Speicherplatz',
                    checklist: 'Checkliste',
                    lastWish: 'Der letzte Wunsch',
                    personalMessage: 'Persönliche Botschaft',
                    personalDocuments: 'Persönliche Unterlagen',
                    documentTemplates: 'Dokumentvorlagen',
                    vault: 'Digitale Tresor',
                    up: 'Up',
                }
            }, security: {
                dsgvo: 'DSGVO', dataStore: 'Datenspeicher in Deutschland', ssl: 'SSL-Verschlüsselung'
            }, inCooperationWith: 'In Zusammenarbeit mit', features: {
                personalMessage: {
                    title: 'Persönliche Botschaft', description: 'Verfasse und hinterlege persönliche Botschaften für deine Liebsten.'
                }, checklist: {
                    title: 'Checkliste', description: 'Mit unserer Checkliste behältst du im Todesfall den Überblick.'
                }, vault: {
                    title: 'Digitale Tresor', description: 'Lade wichtige Dokumente hoch und erhalte alle Unterlagen von unseren Partnern.'
                }, lastWish: {
                    title: 'Der letzte Wunsch', description: 'Halte deinen letzten Wunsch so detailliert wie möglich fest.'
                }, up: {
                    title: 'Up', description: 'Gestalte bereits jetzt dein Leben nach dem Tod im virtuellen Himmel von Geras24.'
                }, insurance: {
                    title: 'Persönliche Absicherung', description: 'Unschlagbare Versicherungen mit Bestleistungen von unserem Partner alleato.'
                }
            }, reviews: {
                loadingError: 'Die Bewertungen konnten nicht geladen werden.',
            }
        }, products: {
            checklist: {
                introTitle: 'Die Checkliste nach einem Todesfall',
                introDescription: 'Stell dir vor, ein geliebter Mensch ist plötzlich verstorben und du stehst als Angehöriger vor der Aufgabe, alle Angelegenheiten zu regeln. Doch keine Sorge, wir haben eine kostenlose Checkliste für dich erstellt, die dir Schritt für Schritt zeigt, was nach einem Todesfall zu erledigen ist. Von der Kündigung von Verträgen bis hin zur Auszahlung von Lebensversicherungen - wir haben alles im Blick. Als Mitglied bei Geras24 kannst du dich darauf verlassen, dass wir dir diese mühsame Arbeit abnehmen. Denn mit uns kannst du bereits zu Lebzeiten alles Wichtige regeln. Lass uns dir helfen und werde noch heute Mitglied!',
            },
            lastWish: {
                introTitle: 'Der letzte Wunsch',
                introDescription: 'Jeder hat für die Zeit nach seinem Tod eine andere Vorstellung davon, was er als richtig und schön empfindet. Viele können sich eine klassische Beerdigung nicht vorstellen. Sie wollen keine trauernden Gäste, sondern Freunde und liebe Menschen um sich wissen, die an die schönen Zeiten denken. Nur du bist in der Lage, schon zu Lebzeiten zu bestimmen, was dir wichtig ist. Halte deinen letzten Wunsch so detailliert wie möglich fest und hinterlege ihn auf der Plattform von Geras24. So erleichterst du deinen Liebsten die ohnehin schwere Zeit nach deinem Tod. Werde noch heute ein Mitglied der Geras24 Gemeinschaft und halte fest, wie dein letzter Wunsch genau aussieht.',
            },
            personalMessage: {
                introTitle: 'Persönliche Botschaft',
                introDescription: 'Eine ganz besondere Funktion bei Geras24 ist die persönliche Botschaft. Hier hast du die Möglichkeit, deinen Liebsten Videos, Sprachnachrichten oder Briefe zukommen zu lassen. So bleibst du ihnen auch über den Tod hinaus erhalten und schaffst wertvolle Momente.',
            },
            digitalSafe: {
                introTitle: 'Der digitale Tresor',
                introDescription: 'Eines der Hauptprodukte von Geras24 ist die Möglichkeit, alle wichtigen Dokumente sicher hochzuladen. Hier findest du alles, was du für deine tägliche Buchhaltung benötigst, wie zum Beispiel Kontoauszüge, Versicherungspolicen oder Arztrechnungen. Doch nicht nur für den aktuellen Bedarf ist diese Funktion nützlich - du kannst auch alle Dokumente sicher abspeichern, um für den Fall eines schweren Unfalls oder im Todesfall sicherzustellen, dass deine Hinterbliebenen auf alle wichtigen Unterlagen zugreifen können. Registriere dich jetzt kostenlos und nutze diese wertvolle Funktion!',
            },
        },
        footer: {
            personalMessage: 'Persönliche Botschaft',
            checklist: 'Checkliste',
            vault: 'Digitale Tresor',
            lastWish: 'Der letzte Wunsch',
            emergencyBox: 'Notfallbox',
            up: 'Up',
            help: 'Hilfe',
            contact: 'Kontakt',
            aboutUs: 'Über uns',
            geras24Shop: 'Geras24 Shop',
            allRightsReserved: 'Alle Rechte vorbehalten.',
            imprint: 'Impressum',
            privacy: 'Datenschutz',
            terms: 'Allgemeine Verkaufsbedingungen',
        }
    }
}
