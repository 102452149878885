import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import createMutationsSharer from "vuex-shared-mutations";
import {selectedLocale} from '../plugins/i18n'

// Import Modules
import actions from './actions'
import mutations from './mutations'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [
        createPersistedState(),
        createMutationsSharer({
            predicate: [
                "logout",
                "auth_success",
                "auth_error",
            ]
        })
    ],
    state: {
        frontUrl: process.env.VUE_APP_FRONT_URL,
        status: '',
        token: localStorage.getItem('token') || '',
        tresorToken: localStorage.getItem('tresorToken') || '',
        user: {},
        secondUser: null,

        cart_id: null,
        locale: selectedLocale,
        settings: JSON.parse(localStorage.getItem('settings')) || {},
        wa_status: true,

        barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
        barImage: '/assets/img/bg.png',
        drawer: true,
    },
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        user: state => state.user,
        cart_id: state => state.cart_id,
        settings: state => state.settings,
        wa_status: state => state.wa_status,
        token: state => state.token,
        tresorToken: state => state.tresorToken,
        secondUser: state => state.secondUser,
        frontUrl: state => state.frontUrl,
    },
    mutations,
    actions
})
