<template>
  <v-container>
    <v-skeleton-loader
        type="image"
        v-if="reviewsLoading"
        class="mb-4 mt-4"
    >
    </v-skeleton-loader>
    <v-slide-group
        show-arrows
        ref="slideGroup"
    >
      <v-slide-item
          v-for="(review, index) in reviews"
          :key="index"
          class="mt-4 mb-4 ml-3 mr-3"
          :style="{ width: slideItemWidth + 'px' }"
          ref="slideItem"
      >
        <v-card :elevation="5">
          <v-card-text class="rating-card-text text--primary d-flex flex-column justify-space-between">
            <div>
              <v-rating
                  v-model="review.rating"
                  dense
                  readonly
                  color="primary"
                  background-color="gray"
                  size="20"
                  class="mb-3"
              ></v-rating>
              <p>„{{ review.text }}”</p>
            </div>
            <div class="d-flex align-center">
              <v-img
                  src="/assets/img/icons/google.svg"
                  height="30px"
                  width="30px"
                  contain
                  class="flex-grow-0"
              ></v-img>
              <div class="d-flex flex-1 flex-column ml-2">
                <span class="font-weight-medium">{{ review.author_name }}</span>
                <span class="text-caption">{{ formatTimestamp(review.time) }}</span>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-slide-item>
    </v-slide-group>
    <v-alert
        v-if="!reviewsLoading && !reviews.length"
        color="warning"
        icon="$error"
        class="white--text"
        elevation="5"
    >
      {{ $t('texts.home.reviews.loadingError') }}
    </v-alert>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      reviews: [],
      reviewsLoading: true,
      slideItemWidth: 0,
      slideItemMargin: 24,
    };
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
  },
  methods: {
    async fetchGoogleReviews() {
      try {
        const response = await axios.get('greviews');

        if (response.data.reviews) {
          this.reviews = response.data.reviews;
        }
      } catch (error) {
        console.error('Error fetching reviews:', error);
      } finally {
        this.reviewsLoading = false;

        setTimeout(() => {
          this.calculateSlideItemWidth();
        }, 50);
      }
    },
    calculateSlideItemWidth() {
      const slideGroup = this.$refs.slideGroup;

      if (slideGroup) {
        const wrapper = slideGroup.$el.querySelector('.v-slide-group__wrapper');

        if (wrapper) {
          const wrapperWidth = wrapper.clientWidth;
          let slidesPerView = 3;

          if (this.breakpoint.mdAndDown) {
            slidesPerView = 2;
          }

          if (this.breakpoint.smAndDown) {
            slidesPerView = 1;
          }

          this.slideItemWidth = (wrapperWidth / slidesPerView) - this.slideItemMargin;
        }
      }
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp * 1000);
      const formatter = new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour12: false
      });

      return formatter.format(date);
    },
    handleResize() {
      this.calculateSlideItemWidth();
    },
  },
  created() {
    this.fetchGoogleReviews();
    window.addEventListener('resize', this.handleResize);
  },
  mounted() {
    this.calculateSlideItemWidth();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style scoped>
.rating-card-text {
  height: 100%;
}
</style>
